@import './assets/iconfont/material-icons.css';
@import '~@angular/material/theming';
@import './app/dialogs/message/message-theme';
@import './app/shared/file/_file-theme';
@include mat-core();
@import 'styles/utilities';

// indigo theme
$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-deep-orange);
$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);
@include app-message-theme($theme);
@include td-file-theme($theme);
@include covalent-utilities();

// native material table
table.mat-table {
    border-spacing: 0;
}

tr.mat-header-row {
    height: 56px;
}

tr.mat-row, tr.mat-footer-row {
    height: 48px;
}

th.mat-header-cell {
    text-align: left;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

th.mat-header-cell:first-child, td.mat-cell:first-child, td.mat-footer-cell:first-child {
    padding-left: 24px;
}

th.mat-header-cell:last-child, td.mat-cell:last-child, td.mat-footer-cell:last-child {
    padding-right: 24px;
}

td.fit, th.fit {
    width: 1px;
    white-space: nowrap;
}

.select-none {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.material-thumbnail {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    vertical-align: middle;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-auto {
    flex: auto;
}

.flex-none {
    flex: none;
}

// https://github.com/angular/material2/issues/4591
mat-tab-group.flex-auto .mat-tab-body-wrapper {
    flex-grow: 1;

    .mat-tab-body.mat-tab-body-active {
        position: absolute;
    }
}

.mat-menu-panel.wide-menu {
    max-width: 540px;
}

.mat-select-panel.wide {
    max-width: 540px;
}

tr.cdk-drag-preview {
    display: inline-flex;
}

.disabled-element {
    background-color: gray;
    border-radius: 4px;
}

.wp-experimental {
    display: none !important;
}